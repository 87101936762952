import React from "react";
import { MapContainer, TileLayer, Polygon, Popup, useMap } from "react-leaflet";
import * as L from "leaflet";
import Proj from "proj4leaflet";
import "./styles.css";
import "leaflet-tooltip-layout";

const MAX_ZOOM = 7;
const TILE_SIZE = 512;

const extent = Math.sqrt(2) * 6371007.2;
const resolutions = Array(MAX_ZOOM + 1)
    .fill()
    .map((_, i) => extent / TILE_SIZE / Math.pow(2, i - 1));

export const ARCTIC_LAEA = new Proj.CRS(
    "EPSG:3575",
    "+proj=laea +lat_0=90 +lon_0=10 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs",
    {
        origin: [-extent, extent],
        bounds: L.bounds(L.point(-extent, extent), L.point(extent, -extent)),
        resolutions: resolutions,
    }
);

const arcticBasinCoordinates = [
    [
        [83.1214554158, 18.0009223348],
        [82.7803306105, 13.0829457022],
        [82.6615587414, 11.6252723085],
        [81.6610218388, 2.3015099129],
        [82.8061024763, -6.4129863123],
        [83.6495821923, -17.0104398856],
        [84.137756637, -26.695040929],
        [83.2963737749, -32.011879219],
        [82.1727003887, -36.7047299897],
        [81.3094880145, -39.4674664914],
        [80.3406593391, -61.9607628988],
        [80.3294980435, -65.9068020357],
        [80.3365186057, -69.6093928675],
        [80.3327804683, -72.6494080105],
        [80.3396455388, -75.3989377792],
        [79.973731858, -77.1787615304],
        [79.7231043234, -78.3709576716],
        [79.4711215041, -79.5875542846],
        [79.4874173389, -83.1821312236],
        [79.52878895, -86.1464193987],
        [79.5521128645, -89.6229659089],
        [79.5831013166, -93.0161437317],
        [79.5855593821, -96.4728485319],
        [79.076617549, -98.6631028971],
        [78.4519352807, -101.3839057358],
        [77.9918467381, -103.4170261808],
        [77.5418924176, -105.3701498049],
        [77.1708811805, -106.9091016406],
        [77.1578982644, -109.992722724],
        [78.1222748427, -110.0270564424],
        [79.0800047722, -110.009593157],
        [79.43964369, -113.3140867146],
        [79.7047192367, -116.6355112538],
        [79.9490417385, -119.8136628539],
        [80.2147496821, -122.7281764672],
        [80.6001357361, -128.3785795604],
        [80.8766272652, -133.9059402204],
        [81.0775800135, -138.4897934374],
        [81.2505869511, -141.1223480224],
        [80.5686763828, -141.2350322059],
        [79.4686604731, -141.0784327721],
        [78.4022014294, -141.0396884541],
        [77.4429838855, -141.0143764724],
        [75.6956205053, -141.0247838542],
        [74.2788484737, -141.0187738691],
        [73.365417072, -141.0147757167],
        [72.7624492611, -141.0087391535],
        [72.7896695676, -145.8645313094],
        [72.7437323055, -150.392581769],
        [72.6569666265, -154.7671571093],
        [72.5125333076, -159.31653843],
        [72.3438914505, -163.0318149861],
        [72.1884084672, -165.8380066246],
        [72.9861856079, -171.9353785414],
        [73.5938699722, -179.121316413],
        [73.9173639375, 172.2796095758],
        [76.3360300391, 164.4575256792],
        [78.734353547, 150.6812791311],
        [79.9558645068, 136.5217756746],
        [80.4009153149, 118.3304450992],
        [80.9563231349, 112.7093494698],
        [81.5231725062, 105.0202423287],
        [82.0321449177, 91.3790125136],
        [82.7389978078, 82.1225433027],
        [83.3028185702, 68.689315413],
        [83.5908139047, 61.8683296873],
        [83.8553131852, 48.3919706318],
        [83.5923484207, 28.0657678849],
    ],
];

const baffinBayCoordinates = [
    [
        [76.6692796318, -75.5062857195],
        [76.9976346854, -75.500078888],
        [77.0453518175, -73.8089618509],
        [77.0999098226, -71.4132859719],
        [77.136604338, -68.8035385231],
        [77.1424396991, -65.6405025581],
        [77.0947153355, -62.0217505473],
        [76.0801137404, -57.6500574978],
        [75.0290197881, -53.9525514251],
        [74.2400601666, -51.6202615079],
        [73.2565690799, -50.9513330093],
        [72.1500789747, -50.3564371863],
        [70.900826141, -49.7571975427],
        [69.8322131102, -49.2741316084],
        [68.7098275395, -48.8607340505],
        [67.4817984426, -48.4147263504],
        [66.5852009424, -48.11458001],
        [65.7449416966, -47.8703029927],
        [66.005730439, -51.5494159982],
        [66.1822696425, -55.4123699858],
        [66.240578643, -59.6462523248],
        [66.1730495634, -63.6211355445],
        [66.7675942704, -65.5289141217],
        [67.4681740674, -68.1065618975],
        [67.9438678702, -70.0008345639],
        [69.0291316714, -71.7701160406],
        [70.0484400734, -73.5843766979],
        [70.8449226736, -75.111782173],
        [70.909386365, -78.7145707522],
        [70.8869622535, -82.0516873101],
        [70.9759083557, -82.0489659636],
        [71.0648766805, -82.0462185861],
        [71.1538671336, -82.0434448036],
        [71.2428796164, -82.0406442385],
        [71.3319140334, -82.0378164927],
        [71.4209702887, -82.0349611742],
        [71.5100482848, -82.0320778721],
        [71.599147925, -82.029166181],
        [71.688271251, -82.0271802064],
        [71.7774187995, -82.026402549],
        [71.8665877473, -82.0256171209],
        [71.955777998, -82.0248237984],
        [72.0449894554, -82.024022468],
        [72.1342220218, -82.0232130058],
        [72.223475599, -82.0223952859],
        [72.3127500913, -82.0215691846],
        [72.4020453996, -82.0207345732],
        [72.4913614275, -82.0198913145],
        [72.5806980767, -82.0190392759],
        [72.6700552483, -82.0181783178],
        [73.485125422, -82.009840721],
        [74.1706065846, -80.0659951329],
        [74.9017809058, -77.6440423788],
        [75.5252845148, -75.5238622147],
        [75.5824193603, -75.522407958],
        [75.6395611223, -75.5209420083],
        [75.6967097748, -75.5194642289],
        [75.7538652925, -75.5179744697],
        [75.8110276495, -75.5164725896],
        [75.8681968178, -75.5149584362],
        [75.9253727713, -75.5134318637],
        [75.9825554855, -75.5118927139],
        [76.0397449311, -75.5103408284],
        [76.0969420238, -75.5097090258],
        [76.1541461132, -75.5093795446],
        [76.2113568667, -75.5090473036],
        [76.2685742573, -75.5087122713],
        [76.3257982597, -75.5083744057],
        [76.383028847, -75.5080336741],
        [76.440265992, -75.5076900378],
        [76.4975096712, -75.5073434623],
        [76.5547598543, -75.5069939136],
        [76.6120165163, -75.5066413433],
    ],
];

const barentsSeaCoordinates = [
    [
        [83.1214554158, 18.0009223348],
        [83.5923484207, 28.0657678849],
        [83.8553131852, 48.3919706318],
        [83.5908139047, 61.8683296873],
        [83.3028185702, 68.689315413],
        [81.7967087191, 68.1683808654],
        [79.8654650939, 67.7401754033],
        [78.1247277361, 67.5016627564],
        [76.479959049, 67.3024646671],
        [76.4520780013, 67.0519486505],
        [76.4239428959, 66.8024755811],
        [76.3955553775, 66.5540483653],
        [76.3669170958, 66.3066697178],
        [76.3380296973, 66.0603421841],
        [76.3088948311, 65.8150681234],
        [76.2795141455, 65.5708497189],
        [76.2498892903, 65.3276889966],
        [76.2199959584, 65.0856446704],
        [76.1897628007, 64.8448810732],
        [76.1592909846, 64.6051833271],
        [76.1285821601, 64.3665527073],
        [76.0976379712, 64.1289903265],
        [76.0664600669, 63.8924971389],
        [76.035050087, 63.6570739462],
        [76.0034096733, 63.4227214045],
        [75.9715404622, 63.1894400052],
        [75.9394440892, 62.9572301075],
        [75.907122186, 62.7260919216],
        [75.8745763816, 62.4960255176],
        [75.4156805248, 59.4555329582],
        [74.5924355115, 57.505779169],
        [73.3425368102, 55.0199658898],
        [72.5474679429, 53.6453063501],
        [71.7861225548, 52.4325068531],
        [71.8004008241, 46.1007052146],
        [71.5292876622, 39.1097817743],
        [71.1554096475, 34.0722336872],
        [72.3017673824, 26.9935914021],
        [73.1198892366, 19.1944746867],
        [73.4716286804, 13.9239750045],
        [74.4419270894, 9.4324125251],
        [75.2801056903, 4.6376764043],
        [76.4966079209, 4.3411706958],
        [78.4517610958, 3.8207646876],
        [80.4114191641, 3.1584024149],
        [81.6610218388, 2.3015099129],
        [82.6615587414, 11.6252723085],
        [82.7803306105, 13.0829457022],
    ],
];

const chukchiSeaCoordinates = [
    [
        [66.972629726, 171.0800305115],
        [67.9049459435, 170.6590719441],
        [69.1048558828, 170.0712545149],
        [70.6428563979, 169.2205377041],
        [71.4621813556, 169.864072586],
        [72.8085144081, 171.0440785401],
        [73.9173639375, 172.2796095758],
        [73.5938699722, -179.121316413],
        [72.9861856079, -171.9353785414],
        [72.1884084672, -165.8380066246],
        [71.0522384994, -164.5407182294],
        [69.6203314058, -163.0780502344],
        [68.6639342605, -162.2244818486],
        [67.9022671953, -161.5331236677],
        [65.8319437658, -160.0379176984],
        [64.7569344388, -160.1049041641],
        [63.6180061565, -160.1481674382],
        [61.6925554355, -160.207448197],
        [61.0329987642, -164.6990349194],
        [60.0411915271, -169.5791414403],
        [59.1951401782, -172.8236141026],
        [58.1276543921, -176.2656111129],
        [59.2147914635, -178.187142248],
        [60.4367880972, 179.3456216199],
        [61.8621985035, 175.9821775324],
        [63.1396307982, 172.4105792755],
        [64.8920101134, 171.8587749947],
    ],
];

const davisStraitCoordinates = [
    [
        [46.6895628389, -55.9193945974],
        [46.6897905608, -55.9194861096],
        [46.6941111106, -55.9212222198],
        [46.6981458313, -55.9235336105],
        [46.7021802811, -55.92584528],
        [46.7062147208, -55.9281572198],
        [46.7102491706, -55.9304697203],
        [46.7142836107, -55.9327824996],
        [46.7183180613, -55.93509556],
        [46.7223522209, -55.9374088903],
        [46.7263863907, -55.9397227795],
        [46.7304205609, -55.94203694],
        [46.7344547206, -55.9443513902],
        [46.7384888907, -55.94666611],
        [46.7425230615, -55.9489811096],
        [46.7465569409, -55.9512966694],
        [46.7505908312, -55.9536125003],
        [46.754624721, -55.9559288903],
        [46.7586586111, -55.95824528],
        [46.7626925011, -55.9605622196],
        [46.7667263909, -55.9628794395],
        [46.770760001, -55.9651969405],
        [46.7747936114, -55.9675150002],
        [46.7788272212, -55.9698330596],
        [46.7828608308, -55.9721516695],
        [46.7868941712, -55.9744708301],
        [46.790927781, -55.9767900003],
        [46.7949611112, -55.9791097197],
        [46.798994441, -55.98142972],
        [46.803027781, -55.9837500006],
        [46.8055300012, -55.9888550001],
        [46.8080322211, -55.99396056],
        [46.8105338913, -55.9990666701],
        [46.813035561, -56.0041730598],
        [46.8155369409, -56.0092799995],
        [46.8180380612, -56.0143875002],
        [46.820538891, -56.0194952794],
        [46.8230397207, -56.0246036099],
        [46.8255402812, -56.0297125],
        [46.8280402811, -56.0348219396],
        [46.8305402813, -56.0399316701],
        [46.8330402812, -56.0450419403],
        [46.8355397208, -56.0501525004],
        [46.8380388908, -56.0552638894],
        [46.8405380607, -56.0603755597],
        [46.843036941, -56.0654877795],
        [46.8455355613, -56.0706002805],
        [46.8480338912, -56.0757133306],
        [46.8505322212, -56.0808269401],
        [46.8530300009, -56.0859411104],
        [46.8555277813, -56.0910555603],
        [46.8577500012, -56.0947913901],
        [46.8599722207, -56.0985277796],
        [46.8642666713, -56.1001938897],
        [46.8685608311, -56.10186028],
        [46.872855281, -56.1035272205],
        [46.8771494406, -56.1051941697],
        [46.8814436107, -56.10686139],
        [46.8857377811, -56.1085288895],
        [46.890031941, -56.1101966698],
        [46.8943261113, -56.1118647204],
        [46.8986202807, -56.1135327804],
        [46.9029144409, -56.1152013903],
        [46.9072083314, -56.1168702799],
        [46.9115025007, -56.1185394394],
        [46.9157963912, -56.1202088905],
        [46.9200905608, -56.1218783301],
        [46.9243844408, -56.1235483304],
        [46.9286783307, -56.1252183304],
        [46.9329722207, -56.1268888898],
        [46.9374305612, -56.1263380603],
        [46.9418891711, -56.1257869404],
        [46.9463475011, -56.1252358298],
        [46.9508061111, -56.1246847196],
        [46.955264441, -56.1241333296],
        [46.9597227808, -56.1235819401],
        [46.9641811107, -56.1230305598],
        [46.9686397213, -56.1224788896],
        [46.973098061, -56.1219272206],
        [46.9775563909, -56.1213755596],
        [46.9820147213, -56.1208236105],
        [46.9864730613, -56.1202716703],
        [46.9909316712, -56.1197197203],
        [46.9953900009, -56.1191674996],
        [46.9998483306, -56.1186152801],
        [47.004306671, -56.1180630601],
        [47.0087650009, -56.1175105595],
        [47.0132233308, -56.11695806],
        [47.0176816713, -56.11640556],
        [47.0221400011, -56.1158527804],
        [47.0265983311, -56.1152999995],
        [47.0310566706, -56.1147472201],
        [47.0355150014, -56.1141941699],
        [47.039973331, -56.1136411097],
        [47.0444316714, -56.1130880598],
        [47.0488897206, -56.11253472],
        [47.0533480607, -56.1119813894],
        [47.0578063908, -56.1114280594],
        [47.0622647211, -56.1108747198],
        [47.0667230613, -56.1103211097],
        [47.0711811113, -56.1097672195],
        [47.0756394411, -56.1092136097],
        [47.0800977812, -56.1086597199],
        [47.0845561114, -56.1081055596],
        [47.0890141707, -56.1075516702],
        [47.0934725007, -56.1069974998],
        [47.0979305609, -56.1064433297],
        [47.102388891, -56.1058888903],
        [47.1064391713, -56.1087349998],
        [47.1104891707, -56.1115813899],
        [47.1145391708, -56.1144283301],
        [47.1185888912, -56.1172755597],
        [47.1226388914, -56.12012333],
        [47.1266886109, -56.1229716705],
        [47.1307383313, -56.1258202796],
        [47.1347880612, -56.1286694395],
        [47.1388375013, -56.1315188901],
        [47.1428869413, -56.1343688902],
        [47.1469363911, -56.1372191702],
        [47.1509858309, -56.1400699995],
        [47.155035001, -56.1429211099],
        [47.1590841711, -56.14577278],
        [47.1631333312, -56.1486249999],
        [47.1671822207, -56.1514774996],
        [47.1712313911, -56.1543302801],
        [47.1752802811, -56.1571838895],
        [47.1793291707, -56.1600375002],
        [47.1833777813, -56.1628919405],
        [47.1874263913, -56.1657466707],
        [47.1914750008, -56.1686016705],
        [47.1955236107, -56.1714572204],
        [47.199571941, -56.1743133297],
        [47.2036202807, -56.1771697203],
        [47.2076686112, -56.1800266706],
        [47.2117169412, -56.1828838898],
        [47.2157650011, -56.1857416704],
        [47.2198130606, -56.1885997202],
        [47.2238611107, -56.1914583301],
        [47.2279091711, -56.1943175002],
        [47.2319569407, -56.1971769396],
        [47.2360047213, -56.2000369396],
        [47.2400525007, -56.2028972198],
        [47.2441000009, -56.2057580596],
        [47.2481475012, -56.2086191705],
        [47.2521950009, -56.2114808294],
        [47.2562425011, -56.2143430598],
        [47.2602897209, -56.2172055595],
        [47.2643369407, -56.2200686102],
        [47.2683841707, -56.2229319404],
        [47.2724313912, -56.22579583],
        [47.2764783306, -56.2286599999],
        [47.2805252807, -56.2315247195],
        [47.284572221, -56.2343900001],
        [47.2886191706, -56.2372555605],
        [47.2926658306, -56.2401213905],
        [47.2967125006, -56.24298806],
        [47.3007591706, -56.2458547198],
        [47.3048055607, -56.2487222204],
        [47.306284441, -56.2549272196],
        [47.3077627811, -56.2611327805],
        [47.3092408308, -56.2673383296],
        [47.310718611, -56.27354444],
        [47.3121961111, -56.2797511098],
        [47.3136733307, -56.2859577801],
        [47.3151500008, -56.2921649995],
        [47.3166263908, -56.2983724994],
        [47.3181025007, -56.3045802798],
        [47.3195783312, -56.3107883299],
        [47.3210536111, -56.3169969406],
        [47.322528891, -56.3232058297],
        [47.3240036108, -56.3294149998],
        [47.3254780611, -56.3356247203],
        [47.3269519411, -56.3418344398],
        [47.328425831, -56.3480447194],
        [47.3298991711, -56.3542552805],
        [47.3313722213, -56.3604663905],
        [47.3328450007, -56.3666774996],
        [47.3343175006, -56.3728891703],
        [47.3357894411, -56.3791013898],
        [47.3372611107, -56.3853136093],
        [47.3387325006, -56.3915263904],
        [47.3402036108, -56.39773917],
        [47.341674441, -56.4039525001],
        [47.3431447208, -56.4101663897],
        [47.3446147212, -56.4163802803],
        [47.3460844409, -56.4225947198],
        [47.3475538913, -56.4288094398],
        [47.3490227811, -56.4350247197],
        [47.3504913906, -56.44124],
        [47.3519597209, -56.4474558296],
        [47.3534277808, -56.4536719404],
        [47.3548955608, -56.4598883296],
        [47.3563627813, -56.4661052799],
        [47.3578297214, -56.4723224997],
        [47.3592963911, -56.4785399995],
        [47.3607627813, -56.4847577799],
        [47.3622288911, -56.4909758299],
        [47.3636944411, -56.4971944405],
        [47.3600938911, -56.5011419402],
        [47.3564930609, -56.5050891704],
        [47.3528919408, -56.5090355602],
        [47.3492908307, -56.5129813893],
        [47.345689721, -56.5169269402],
        [47.3420883314, -56.5208716697],
        [47.3384869406, -56.5248161098],
        [47.3348852808, -56.5287600002],
        [47.3312833314, -56.5327033299],
        [47.327681671, -56.5366458304],
        [47.3240794411, -56.5405880598],
        [47.3204772212, -56.5445297197],
        [47.3168750012, -56.54847083],
        [47.3132725008, -56.5524116703],
        [47.3096700013, -56.5563516705],
        [47.3060672209, -56.5602911095],
        [47.3027602806, -56.5639066702],
        [47.3024644413, -56.5642299998],
        [47.2988613911, -56.5681686099],
        [47.2952580607, -56.57210639],
        [47.2916550011, -56.5760438899],
        [47.2880513912, -56.5799808301],
        [47.2844477807, -56.5839169402],
        [47.2808441711, -56.5878527798],
        [47.2772402808, -56.5917880601],
        [47.2736363909, -56.5957227802],
        [47.2700322206, -56.59965694],
        [47.2664280611, -56.6035905598],
        [47.2628236111, -56.6075236105],
        [47.2592191706, -56.61145639],
        [47.2556144415, -56.6153883296],
        [47.2520097213, -56.6193197206],
        [47.2484047205, -56.6232508304],
        [47.2447997208, -56.6271811096],
        [47.2411944411, -56.6311111102],
        [47.2380941706, -56.6350397196],
        [47.2380044407, -56.6351536097],
        [47.2349938908, -56.6389680601],
        [47.2318933311, -56.6428958304],
        [47.2287927813, -56.6468230605],
        [47.225691941, -56.6507500006],
        [47.2225911107, -56.6546763897],
        [47.2194900006, -56.6586022198],
        [47.2163888907, -56.6625277794],
        [47.214048331, -56.6681011102],
        [47.2117077808, -56.6736741694],
        [47.2093666711, -56.6792463904],
        [47.2070255608, -56.6848183296],
        [47.2046838908, -56.6903897197],
        [47.2023419408, -56.69596083],
        [47.2000000012, -56.7015311099],
        [47.1976575009, -56.7071011102],
        [47.1953150012, -56.7126705605],
        [47.1929719409, -56.7182394395],
        [47.1906288911, -56.7238080595],
        [47.1882855606, -56.7293761102],
        [47.1859416709, -56.7349436104],
        [47.1835977812, -56.7405105606],
        [47.1812536109, -56.7460772198],
        [47.1789088909, -56.7516430602],
        [47.1765641712, -56.7572086098],
        [47.1742191708, -56.7627736105],
        [47.171873611, -56.7683383302],
        [47.1695280613, -56.7739024995],
        [47.1671822213, -56.7794658304],
        [47.164836111, -56.7850291697],
        [47.1624897211, -56.7905916702],
        [47.160142781, -56.7961538897],
        [47.1577958313, -56.8017152797],
        [47.1554486112, -56.8072763902],
        [47.1531011112, -56.8128372205],
        [47.1507533307, -56.8183972202],
        [47.148405281, -56.8239569395],
        [47.1460569412, -56.8295161107],
        [47.1437083313, -56.8350747205],
        [47.1413594409, -56.8406330596],
        [47.1390102808, -56.8461908302],
        [47.1366611114, -56.8517480596],
        [47.1343113907, -56.8573047196],
        [47.1319613911, -56.8628608299],
        [47.1296111113, -56.8684166695],
        [47.1265602813, -56.8731869405],
        [47.1235091712, -56.8779566703],
        [47.120457781, -56.8827258301],
        [47.1174063913, -56.8874947206],
        [47.1143547206, -56.8922627799],
        [47.111303061, -56.8970302796],
        [47.1082508309, -56.9017975001],
        [47.1051986106, -56.9065638903],
        [47.1021461109, -56.9113297205],
        [47.0990933311, -56.9160952805],
        [47.0960405613, -56.9208599996],
        [47.0929875011, -56.9256244406],
        [47.0899341706, -56.9303880601],
        [47.0868808308, -56.9351513898],
        [47.083827221, -56.93991417],
        [47.0807733308, -56.9446761101],
        [47.0777191714, -56.9494377799],
        [47.0746650007, -56.9541988903],
        [47.0716105612, -56.9589594396],
        [47.0685558312, -56.9637194404],
        [47.0655008312, -56.9684788895],
        [47.0624458306, -56.9732377804],
        [47.0593905606, -56.9779961102],
        [47.0563352807, -56.9827538899],
        [47.0532794414, -56.9875111098],
        [47.0502236113, -56.9922677797],
        [47.047167501, -56.9970238902],
        [47.0441113909, -57.0017794395],
        [47.0410550009, -57.0065344405],
        [47.0379983309, -57.0112891704],
        [47.0349413914, -57.0160430598],
        [47.0318844407, -57.0207963896],
        [47.028826941, -57.0255494404],
        [47.0257697211, -57.0303016705],
        [47.022711941, -57.0350536098],
        [47.0196541711, -57.0398047202],
        [47.0165961106, -57.0445555602],
        [47.0135377812, -57.0493058303],
        [47.0104794412, -57.0540552799],
        [47.0074208309, -57.0588044401],
        [47.0043619409, -57.0635530596],
        [47.0013027813, -57.0683011104],
        [46.9982436107, -57.0730486097],
        [46.9951841709, -57.0777955597],
        [46.9921244413, -57.0825419396],
        [46.9890647211, -57.0872877806],
        [46.9860047208, -57.0920330594],
        [46.9829444414, -57.09677778],
        [46.9824177807, -57.0966711097],
        [46.9789641708, -57.0959463898],
        [46.9755150014, -57.09517778],
        [46.972070561, -57.0943649997],
        [46.9686311107, -57.0935088896],
        [46.9651969411, -57.0926088904],
        [46.9617680612, -57.0916652804],
        [46.9583452812, -57.0906780597],
        [46.9549283311, -57.0896472201],
        [46.9515175006, -57.0885733303],
        [46.948113331, -57.0874561105],
        [46.9447161109, -57.0862955604],
        [46.9413258307, -57.0850919402],
        [46.937942781, -57.08384528],
        [46.9345675013, -57.0825558302],
        [46.9311997208, -57.0812236098],
        [46.9278402814, -57.0798486098],
        [46.9244891712, -57.0784311095],
        [46.9211463913, -57.0769711106],
        [46.9178127811, -57.0754688906],
        [46.9144880608, -57.07392444],
        [46.9111727813, -57.0723377795],
        [46.9078669407, -57.0707091694],
        [46.904571111, -57.0690388896],
        [46.9012850009, -57.0673266701],
        [46.8980097211, -57.0655730605],
        [46.8947447208, -57.0637780604],
        [46.8914905608, -57.0619416704],
        [46.8882475007, -57.0600641703],
        [46.885015831, -57.0581458303],
        [46.8817958313, -57.0561866703],
        [46.8785875013, -57.0541866699],
        [46.8753911107, -57.0521461102],
        [46.8722072211, -57.0500655605],
        [46.8690358312, -57.0479444395],
        [46.8658772213, -57.0457833303],
        [46.8627313912, -57.0435825],
        [46.8595991713, -57.0413419403],
        [46.856480001, -57.0390619398],
        [46.8533750011, -57.03674278],
        [46.8502836113, -57.0343841705],
        [46.8472066711, -57.0319866696],
        [46.8441438907, -57.0295505596],
        [46.8410958312, -57.02707556],
        [46.8380627811, -57.0245625005],
        [46.8350447209, -57.02201111],
        [46.8320419413, -57.0194216699],
        [46.829055001, -57.0167944396],
        [46.8260836113, -57.0141297198],
        [46.8231283309, -57.0114275006],
        [46.8201891711, -57.0086883295],
        [46.8172663908, -57.0059119396],
        [46.8143605612, -57.0030988903],
        [46.8114713912, -57.0002494402],
        [46.8085994412, -56.9973636098],
        [46.8057447208, -56.9944416705],
        [46.802907781, -56.99148389],
        [46.8000883308, -56.9884905599],
        [46.7984722208, -56.9867500002],
        [46.795362221, -56.98645722],
        [46.7918825009, -56.9860875],
        [46.7884047207, -56.9856736097],
        [46.784930001, -56.98521556],
        [46.7814577812, -56.9847136095],
        [46.7813938822, -56.9847035491],
        [46.8816413511, -58.302015946],
        [46.960664241, -59.6871822807],
        [48.1619065689, -59.5650908181],
        [49.5928001089, -59.4071795091],
        [51.0769931339, -59.231844578],
        [51.8832291301, -59.144737136],
        [52.4734709491, -60.9505525285],
        [53.0520248404, -62.8664143438],
        [53.6696000664, -65.1978538298],
        [54.3081589423, -67.9351459689],
        [54.7928379257, -70.3961988506],
        [55.2406382433, -72.9967688335],
        [56.1044726419, -72.9976054604],
        [56.9867317764, -72.9971061741],
        [58.0448901973, -73.002565005],
        [59.1108574844, -72.9997400641],
        [59.9994222647, -73.0006982395],
        [60.7212075256, -73.0004798473],
        [61.4605197033, -73.0010471959],
        [62.0004842079, -73.0038846642],
        [62.0077719587, -71.0228224053],
        [62.0131976026, -69.1832855433],
        [62.0207171451, -68.0958265067],
        [62.0185359149, -67.0081518154],
        [62.3370154849, -66.9997022743],
        [62.5447747818, -67.502168341],
        [63.0215108732, -68.7022500004],
        [63.3830058533, -69.6278815303],
        [64.3040355098, -69.676740296],
        [65.2843489474, -69.7546440113],
        [66.2853741162, -69.8494829967],
        [67.1367194304, -69.9079623463],
        [67.9438678702, -70.0008345639],
        [67.4681740674, -68.1065618975],
        [66.7675942712, -65.5289141225],
        [66.1730495643, -63.6211355452],
        [66.240578643, -59.6462523248],
        [66.1822696425, -55.4123699858],
        [66.005730439, -51.5494159982],
        [65.7449416966, -47.8703029927],
        [64.537203352, -47.7322308414],
        [63.0632441687, -47.5372784776],
        [61.5203694781, -47.3660310978],
        [59.9118850871, -48.1243257692],
        [58.0408528775, -48.9461665817],
        [56.5649814321, -49.5443146189],
        [54.7862047511, -50.2018607234],
        [53.1577886228, -50.7647793311],
        [50.9794135829, -51.4331018456],
        [49.082499253, -51.9633566256],
        [47.7528142876, -52.2970051129],
        [46.311877588, -52.645936491],
        [46.5693157112, -54.697072712],
    ],
];

const eastGreenlandCoordinates = [
    [
        [81.6610218388, 2.3015099129],
        [80.4114191641, 3.1584024149],
        [78.4517610958, 3.8207646876],
        [76.4966079209, 4.3411706958],
        [75.2801056903, 4.6376764043],
        [74.4689043695, 0.2962177655],
        [72.8360741096, -5.8112667934],
        [71.417382352, -9.6989096186],
        [70.2220141794, -12.3914549868],
        [69.701642545, -13.9058430656],
        [68.2429984175, -17.5843541894],
        [67.3187951357, -21.9937941061],
        [66.0185310598, -26.8008143428],
        [64.375343937, -31.5404088347],
        [63.0368969748, -34.6909746967],
        [61.7091898129, -35.99185194],
        [60.0756543032, -37.3998888519],
        [58.7306977973, -38.4389575584],
        [57.820141156, -40.8912372929],
        [56.8544417313, -43.2705614001],
        [56.8606673713, -45.1891951704],
        [56.8141731974, -46.7737567896],
        [57.5162021443, -47.9644734487],
        [58.0408528775, -48.9461665817],
        [59.9118850871, -48.1243257709],
        [61.5203694781, -47.3660310978],
        [61.9605210091, -45.9128297121],
        [62.4389999401, -44.2324256182],
        [62.4294099531, -44.2326982311],
        [60.370254522, -44.2872229675],
        [60.3672961359, -43.9078548518],
        [60.1888263809, -43.9146898591],
        [60.1419015372, -41.556663761],
        [60.1588379677, -41.5457259921],
        [60.281079923, -41.4663988326],
        [60.388918169, -41.3958571074],
        [64.0000059663, -38.680699401],
        [64.0000000003, -38.7],
        [64.0, -38.8000000001],
        [64.0000000003, -38.9000000005],
        [64.0000000001, -39.0000000001],
        [64.0000000002, -39.0999999996],
        [64.0, -39.2],
        [63.9999999999, -39.3000000003],
        [63.9999999995, -39.3999999993],
        [64.0, -39.5],
        [63.9999999996, -39.5999999998],
        [63.9999999998, -39.6999999995],
        [64.0000000004, -39.8000000005],
        [63.9999999996, -39.9000000002],
        [63.9999999996, -39.9999999996],
        [64.0, -40.1],
        [63.9999999997, -40.1999999991],
        [63.9999999999, -40.3000000001],
        [63.9999999999, -40.4000000006],
        [64.0000000003, -40.4999999999],
        [64.0000000002, -40.5999999999],
        [64.0000095102, -40.6458424365],
        [63.9999999997, -40.7000000001],
        [64.0000000002, -40.7999999999],
        [64.0000000004, -40.8999999992],
        [64.0000000004, -40.9999999998],
        [64.0000000003, -41.0999999993],
        [63.9999999998, -41.1999999996],
        [63.9999999996, -41.3000000005],
        [63.9999999996, -41.3999999998],
        [64.0000000001, -41.4999999994],
        [64.0, -41.5999999994],
        [63.9999999998, -41.6999999997],
        [63.9999999998, -41.8000000002],
        [64.0000000002, -41.899999999],
        [63.9999999999, -42.0000000003],
        [64.0000000004, -42.1],
        [64.0000000003, -42.2000000003],
        [63.9999999997, -42.2999999994],
        [64.0000000004, -42.3999999993],
        [64.0000093388, -42.4421274132],
        [65.209688819, -40.9187891322],
        [67.3455822753, -37.6796925361],
        [68.7034829716, -35.1897952885],
        [70.1637149013, -32.0884626013],
        [71.7713171758, -31.1260121252],
        [73.6143608962, -29.6271927745],
        [75.48337086, -27.8100025436],
        [77.447061777, -25.195178729],
        [78.5201670348, -28.0344935081],
        [79.6740596352, -31.8388491431],
        [81.3094880145, -39.4674664914],
        [82.1727003887, -36.7047299897],
        [83.2963737749, -32.011879219],
        [84.137756637, -26.695040929],
        [83.6495821923, -17.0104398856],
        [82.8061024763, -6.4129863123],
    ],
];

const foxeBasinCoordinates = [
    [
        [63.1677084323, -90.7939516115],
        [63.1687063088, -92.5549629658],
        [63.1667759217, -94.253468777],
        [63.1677310419, -95.9237703057],
        [63.1667400612, -97.2523661571],
        [63.6364961259, -97.2547164972],
        [64.2841386684, -97.2525311588],
        [64.9965438696, -97.2646416546],
        [65.6092795688, -96.0067934958],
        [66.223306404, -94.7177058929],
        [67.005280869, -92.9825304649],
        [67.027930387, -90.5128973907],
        [67.02759115, -87.522698797],
        [67.0021527973, -84.9868044784],
        [67.6884107943, -84.619648811],
        [68.2520237271, -84.2978734234],
        [68.7057728416, -84.0271363746],
        [69.1492240821, -84.5492221687],
        [69.522756033, -85.0165089439],
        [70.1731716176, -84.9864498638],
        [70.829158032, -85.0025919747],
        [70.858656155, -83.7135851513],
        [70.8869622535, -82.0516873101],
        [70.909386365, -78.7145707522],
        [70.8449226736, -75.111782173],
        [70.0484400734, -73.5843766979],
        [69.0291316714, -71.7701160406],
        [67.9438678702, -70.0008345639],
        [67.1367194304, -69.9079623463],
        [66.2853741154, -69.8494829957],
        [65.284348947, -69.7546440132],
        [64.3040355098, -69.676740296],
        [63.3830058533, -69.6278815303],
        [63.0215108732, -68.7022500004],
        [62.5447747818, -67.502168341],
        [62.3370154849, -66.9997022743],
        [62.0185359149, -67.0081518154],
        [62.0207171451, -68.0958265067],
        [62.0131976026, -69.1832855433],
        [62.0077719587, -71.0228224053],
        [62.0004842079, -73.0038846642],
        [61.4605197033, -73.0010471959],
        [60.7212075256, -73.0004798473],
        [59.9994222647, -73.0006982395],
        [60.0091728994, -74.5379198345],
        [59.9843329475, -75.845708999],
        [59.9871555481, -76.7978310879],
        [59.9962397357, -78.6807219436],
        [59.9884547965, -80.4418699935],
        [60.0021464434, -82.4185569883],
        [59.997821828, -84.1419359447],
        [59.9985862128, -84.2511948459],
        [59.9992462401, -84.3604591703],
        [59.9998019019, -84.4697281236],
        [60.0002531905, -84.5790009101],
        [60.0006001026, -84.6882767384],
        [60.0008426317, -84.7975548102],
        [60.0009807756, -84.9068343318],
        [60.0010145325, -85.016114508],
        [60.000943902, -85.1253945437],
        [60.0015126681, -85.2346691463],
        [60.0020934721, -85.3439474237],
        [60.0025698932, -85.4532297299],
        [60.0029419264, -85.5625152649],
        [60.003209565, -85.6718032364],
        [60.0033728074, -85.7810928502],
        [60.0034316503, -85.8903833088],
        [60.0033860937, -85.9996738179],
        [60.0032361381, -86.1089635824],
        [60.002981786, -86.2182518081],
        [60.0026230393, -86.3275376975],
        [59.9968837911, -87.5434723739],
        [60.0134114316, -88.4986525363],
        [60.5214012321, -88.4910814353],
        [61.1604663275, -88.4860702225],
        [61.9559161849, -88.4926204285],
        [62.695821262, -88.4923447362],
        [63.1661368933, -88.4971400724],
        [63.1665837399, -88.6119613737],
        [63.1669265726, -88.7267861775],
        [63.1671653867, -88.8416135589],
        [63.1678397577, -88.9564379033],
        [63.1686118625, -89.0712669085],
        [63.1692799467, -89.1861022984],
        [63.1698440003, -89.300943151],
        [63.1703040156, -89.4157885464],
        [63.1706599884, -89.5306375608],
        [63.1709119121, -89.64548927],
        [63.171059784, -89.7603427524],
        [63.1711036022, -89.8751970878],
        [63.171043366, -89.9900513476],
        [63.1708790762, -90.1049046143],
        [63.1706107351, -90.2197559594],
        [63.1702383468, -90.3346044641],
        [63.1697619144, -90.4494492042],
        [63.1691814467, -90.5642892573],
        [63.1684969497, -90.6791236995],
    ],
];

const gulfOfBoothiaCoordinates = [
    [
        [70.829158032, -85.0025919747],
        [70.1731716176, -84.9864498638],
        [69.522756033, -85.0165089439],
        [69.1492240821, -84.5492221687],
        [68.7057728416, -84.0271363746],
        [68.2520237271, -84.2978734234],
        [67.6884107943, -84.619648811],
        [67.0021527973, -84.9868044784],
        [67.02759115, -87.522698797],
        [67.027930387, -90.5128973907],
        [67.005280869, -92.9825304649],
        [67.728592525, -92.9951903408],
        [68.3874656421, -93.0171924751],
        [68.9227872869, -93.003810041],
        [69.4520550539, -93.0075315125],
        [69.899078641, -93.8280852771],
        [70.254197071, -94.507822138],
        [70.7268380063, -94.4997601477],
        [71.2862677162, -94.5123648145],
        [71.9984063986, -94.5052623599],
        [71.6716465827, -92.8260446336],
        [71.3624633105, -91.4089295776],
        [70.8959739114, -89.3994499095],
        [70.8878821131, -88.2137476338],
        [70.8711051994, -86.5847514475],
    ],
];

const kaneBasinCoordinates = [
    [
        [77.483260011, -82.4437497846],
        [78.100806374, -81.8974052598],
        [78.4845732576, -81.5466613778],
        [78.8127756034, -80.8720828992],
        [79.098828162, -80.3299097543],
        [79.4711215041, -79.5875542846],
        [79.7231043234, -78.3709576716],
        [79.973731858, -77.1787615304],
        [80.3396455388, -75.3989377792],
        [80.3327804683, -72.6494080105],
        [80.3365186057, -69.6093928675],
        [80.3294980435, -65.9068020357],
        [80.3406593391, -61.9607628988],
        [79.5652612708, -61.9817203939],
        [78.7582752456, -61.948810201],
        [77.8344952941, -61.9605732487],
        [77.0947153355, -62.0217505473],
        [77.1424396991, -65.6405025581],
        [77.136604338, -68.8035385231],
        [77.0999098226, -71.4132859719],
        [77.0453518175, -73.8089618509],
        [76.9976346854, -75.500078888],
        [76.6692796318, -75.5062857195],
        [76.6707103392, -75.6809712726],
        [76.6720181941, -75.8556930288],
        [76.6732031582, -76.0304477556],
        [76.6742651981, -76.2052322066],
        [76.6752042838, -76.3800431322],
        [76.6760203874, -76.55487729],
        [76.6767134866, -76.7297314215],
        [76.6772835604, -76.9046022792],
        [76.6777305931, -77.079486604],
        [76.6780677358, -77.2543806898],
        [76.6783762084, -77.4292801219],
        [76.6785616146, -77.6041860913],
        [76.6786239492, -77.779095344],
        [76.678563209, -77.9540046156],
        [76.6783793967, -78.1289106538],
        [76.678072517, -78.3038101881],
        [76.6776425786, -78.4786999683],
        [76.6770895955, -78.6535767322],
        [76.6764135816, -78.8284372223],
        [76.6756145565, -79.0032781897],
        [76.6662345367, -80.5015683079],
        [76.6751178384, -80.6249863149],
        [76.6839399171, -80.7485698476],
        [76.6927006494, -80.8723180964],
        [76.7013999071, -80.9962302269],
        [76.7100375658, -81.1203054059],
        [76.7186135007, -81.2445427894],
        [76.7271275876, -81.3689415251],
        [76.735579703, -81.4935007525],
        [76.7439697238, -81.6182196027],
        [76.7522975276, -81.7430971986],
        [76.7605564742, -81.8681407579],
        [76.7686390248, -81.9934816972],
        [76.7766588427, -82.1189765789],
        [76.7846158108, -82.2446244797],
        [76.7925098088, -82.3704244704],
        [76.8003407209, -82.4963756115],
        [76.8081084311, -82.6224769585],
        [76.8158128229, -82.7487275485],
        [76.8234537808, -82.8751264271],
        [76.8310311908, -83.0016726201],
        [76.8636119422, -82.9741912693],
        [76.8961917315, -82.9465721097],
        [76.9287705302, -82.9188141036],
        [76.9613483097, -82.8909162235],
        [76.9939684274, -82.8638783639],
        [77.0265946391, -82.8368636461],
        [77.0592200046, -82.8097115873],
        [77.0918444971, -82.7824211467],
        [77.124468089, -82.7549912762],
        [77.1570907521, -82.7274209113],
        [77.1897124589, -82.6997089943],
        [77.2223331806, -82.6718544395],
        [77.2549528887, -82.6438561562],
        [77.2875715544, -82.6157130424],
        [77.3201891479, -82.5874239825],
        [77.3528056418, -82.5589878541],
        [77.3854210048, -82.5304035256],
        [77.4180352076, -82.5016698462],
        [77.45064822, -82.472785657],
    ],
];

const karaSeaCoordinates = [
    [
        [75.8745763816, 62.4960255176],
        [75.907122186, 62.7260919216],
        [75.9394440892, 62.9572301075],
        [75.9715404622, 63.1894400052],
        [76.0034096733, 63.4227214045],
        [76.035050087, 63.6570739462],
        [76.0664600669, 63.8924971389],
        [76.0976379712, 64.1289903265],
        [76.1285821601, 64.3665527073],
        [76.1592909846, 64.6051833271],
        [76.1897628007, 64.8448810732],
        [76.2199959584, 65.0856446704],
        [76.2498892903, 65.3276889966],
        [76.2795141455, 65.5708497189],
        [76.3088948311, 65.8150681234],
        [76.3380296973, 66.0603421841],
        [76.3669170958, 66.3066697178],
        [76.3955553775, 66.5540483653],
        [76.4239428959, 66.8024755811],
        [76.4520780013, 67.0519486505],
        [76.479959049, 67.3024646671],
        [78.1247277361, 67.5016627564],
        [79.8654650939, 67.7401754033],
        [81.7967087191, 68.1683808654],
        [83.3028185702, 68.689315413],
        [82.7389978078, 82.1225433027],
        [82.0321449177, 91.3790125136],
        [80.7295044171, 95.2307135415],
        [79.4374895332, 96.4321009985],
        [78.6636371516, 102.6802233375],
        [77.7430627769, 103.385597496],
        [76.7091843808, 104.0299981756],
        [75.7224988888, 102.7853752781],
        [74.6060417767, 101.5593684509],
        [73.3429495678, 94.8881790157],
        [72.1330578204, 90.1094383678],
        [71.0770462399, 84.1235294318],
        [69.9110521918, 79.1066284941],
        [68.6457526844, 74.8569601223],
        [67.4697933509, 71.5876626308],
        [66.5268081242, 69.3421229271],
        [66.8494145988, 65.3363791036],
        [67.0692796033, 60.6046570063],
        [67.1208662861, 55.6545526597],
        [66.9357933465, 49.6660370335],
        [66.5388838857, 44.3114792027],
        [68.3576317542, 46.6087815089],
        [70.3047527837, 49.6674009608],
        [71.7861225548, 52.4325068531],
        [72.5474679429, 53.6453063501],
        [73.3425368102, 55.0199658898],
        [74.5924355115, 57.505779169],
        [75.4156805248, 59.4555329582],
    ],
];

const lancasterSoundCoordinates = [
    [
        [76.8310311908, -83.0016726201],
        [76.8234537808, -82.8751264271],
        [76.8158128229, -82.7487275485],
        [76.8081084311, -82.6224769585],
        [76.8003407209, -82.4963756115],
        [76.7925098088, -82.3704244704],
        [76.7846158108, -82.2446244797],
        [76.7766588427, -82.1189765789],
        [76.7686390248, -81.9934816972],
        [76.7605564742, -81.8681407579],
        [76.7522975276, -81.7430971986],
        [76.7439697238, -81.6182196027],
        [76.735579703, -81.4935007525],
        [76.7271275876, -81.3689415251],
        [76.7186135007, -81.2445427894],
        [76.7100375658, -81.1203054059],
        [76.7013999071, -80.9962302269],
        [76.6927006494, -80.8723180964],
        [76.6839399171, -80.7485698476],
        [76.6751178384, -80.6249863149],
        [76.6662345367, -80.5015683079],
        [76.6756145565, -79.0032781897],
        [76.6764135816, -78.8284372223],
        [76.6770895955, -78.6535767322],
        [76.6776425786, -78.4786999683],
        [76.678072517, -78.3038101881],
        [76.6783793967, -78.1289106538],
        [76.678563209, -77.9540046156],
        [76.6786239492, -77.779095344],
        [76.6785616146, -77.6041860913],
        [76.6783762084, -77.4292801219],
        [76.6780677358, -77.2543806898],
        [76.6777305931, -77.079486604],
        [76.6772835604, -76.9046022792],
        [76.6767134866, -76.7297314215],
        [76.6760203874, -76.55487729],
        [76.6752042838, -76.3800431322],
        [76.6742651981, -76.2052322066],
        [76.6732031582, -76.0304477556],
        [76.6720181941, -75.8556930288],
        [76.6707103392, -75.6809712726],
        [76.6692796318, -75.5062857195],
        [76.6120165163, -75.5066413433],
        [76.5547598543, -75.5069939136],
        [76.4975096712, -75.5073434623],
        [76.440265992, -75.5076900378],
        [76.383028847, -75.5080336741],
        [76.3257982597, -75.5083744057],
        [76.2685742573, -75.5087122713],
        [76.2113568667, -75.5090473036],
        [76.1541461132, -75.5093795446],
        [76.0969420238, -75.5097090258],
        [76.0397449311, -75.5103408284],
        [75.9825554855, -75.5118927139],
        [75.9253727713, -75.5134318637],
        [75.8681968178, -75.5149584362],
        [75.8110276495, -75.5164725896],
        [75.7538652925, -75.5179744697],
        [75.6967097748, -75.5194642289],
        [75.6395611223, -75.5209420083],
        [75.5824193603, -75.522407958],
        [75.5252845148, -75.5238622147],
        [74.9017809058, -77.6440423788],
        [74.1706065846, -80.0659951329],
        [73.485125422, -82.009840721],
        [72.6700552483, -82.0181783178],
        [72.5806980767, -82.0190392759],
        [72.4913614275, -82.0198913145],
        [72.4020453996, -82.0207345732],
        [72.3127500913, -82.0215691846],
        [72.223475599, -82.0223952859],
        [72.1342220218, -82.0232130058],
        [72.0449894554, -82.024022468],
        [71.955777998, -82.0248237984],
        [71.8665877473, -82.0256171209],
        [71.7774187995, -82.026402549],
        [71.688271251, -82.0271802064],
        [71.599147925, -82.029166181],
        [71.5100482848, -82.0320778721],
        [71.4209702887, -82.0349611742],
        [71.3319140334, -82.0378164927],
        [71.2428796164, -82.0406442385],
        [71.1538671336, -82.0434448036],
        [71.0648766805, -82.0462185861],
        [70.9759083557, -82.0489659636],
        [70.8869622535, -82.0516873101],
        [70.858656155, -83.7135851513],
        [70.829158032, -85.0025919747],
        [70.8711051994, -86.5847514475],
        [70.8878821131, -88.2137476338],
        [70.8959739114, -89.3994499095],
        [71.3624633105, -91.4089295776],
        [71.6716465827, -92.8260446336],
        [71.9984063986, -94.5052623599],
        [72.0003304903, -94.636547906],
        [72.0021615532, -94.7678612841],
        [72.0038995577, -94.8992011211],
        [72.0055444751, -95.0305660445],
        [72.0070962783, -95.1619546798],
        [72.0085549424, -95.2933656495],
        [72.0094976462, -95.4248369058],
        [72.0102779369, -95.5563273889],
        [72.0109649622, -95.6878287663],
        [72.0115587112, -95.8193396603],
        [72.0120591743, -95.9508586777],
        [72.0124663437, -96.0823844397],
        [72.0127802114, -96.213915556],
        [72.0130007722, -96.3454506445],
        [72.0131280244, -96.4769883176],
        [72.013161964, -96.608527186],
        [72.0131025911, -96.7400658654],
        [72.0129499064, -96.8716029735],
        [72.0127039129, -97.0031371154],
        [72.0123646144, -97.1346669091],
        [72.0024118627, -99.0457543249],
        [72.5095841229, -99.9669571918],
        [72.5013331514, -102.2894608691],
        [72.463369684, -104.9707464144],
        [72.4168486482, -106.8795228562],
        [73.3127180233, -106.8645872408],
        [74.3781632735, -106.9098937877],
        [75.8123986665, -106.9080922515],
        [77.1708811805, -106.9091016406],
        [77.1202911376, -103.2508949409],
        [77.0049277757, -99.5046377889],
        [76.8821983156, -96.8563143106],
        [76.6916531735, -94.9929857924],
        [76.4043830877, -92.629951371],
        [76.5080672208, -89.649972838],
        [76.7409598141, -86.9419523543],
        [76.8282353502, -85.5593252553],
    ],
];

const laptevSeaCoordinates = [
    [
        [74.6060417767, 101.5593684509],
        [75.7224988888, 102.7853752781],
        [76.7091843808, 104.0299981756],
        [77.7430627769, 103.385597496],
        [78.6636371516, 102.6802233375],
        [79.4374895332, 96.4321009985],
        [80.7295044171, 95.2307135415],
        [82.0321449177, 91.3790125136],
        [81.5231725062, 105.0202423287],
        [80.9563231349, 112.7093494698],
        [80.4009153149, 118.3304450992],
        [79.9558645068, 136.5217756746],
        [78.734353547, 150.6812791311],
        [76.3360300391, 164.4575256792],
        [73.9173639375, 172.2796095758],
        [72.8085144081, 171.0440785401],
        [71.4621813556, 169.864072586],
        [70.6428563979, 169.2205377041],
        [69.1048558828, 170.0712545149],
        [67.9049459435, 170.6590719441],
        [66.972629726, 171.0800305115],
        [67.3506403843, 166.1645675064],
        [67.5722255836, 161.0097389761],
        [67.6017169232, 156.4771621196],
        [67.4741666247, 151.5394894841],
        [68.3802982921, 144.5743943007],
        [68.9688923614, 136.2060005868],
        [69.0506806398, 126.4105985666],
        [70.1105474567, 121.5480852702],
        [71.1488786304, 115.0720819771],
        [71.930253496, 107.7728290153],
        [72.4072738872, 99.5880528831],
        [73.5287665628, 100.5130831381],
    ],
];

const mcclintockChannelCoordinates = [
    [
        [71.3039002225, -108.7119442475],
        [71.3067757177, -108.6152147007],
        [71.3095988431, -108.5184551536],
        [71.3123695748, -108.421666142],
        [71.3150878864, -108.3248482074],
        [71.317753755, -108.2280018876],
        [71.3203671549, -108.1311277263],
        [71.3229280642, -108.034226263],
        [71.3254364578, -107.9372980408],
        [71.3278923155, -107.8403436061],
        [71.3302930671, -107.7433629189],
        [71.3324624372, -107.6463170151],
        [71.3345791596, -107.5492484395],
        [71.3366432148, -107.4521577454],
        [71.3386545833, -107.3550454843],
        [71.3406132489, -107.257912207],
        [71.3425191922, -107.1607584614],
        [71.3443723956, -107.0635848039],
        [71.3461728443, -106.9663917874],
        [71.3479205203, -106.8691799618],
        [71.3496154074, -106.7719498855],
        [71.7560866525, -106.8136681895],
        [71.7890982266, -106.816793985],
        [71.8221126805, -106.8199313189],
        [71.8551300075, -106.8230802579],
        [71.8881502029, -106.8262408651],
        [71.9211732617, -106.8294132055],
        [71.9541991781, -106.8325973419],
        [71.9872279486, -106.8357933441],
        [72.0202595659, -106.8390012787],
        [72.0532940255, -106.8422212111],
        [72.0863313222, -106.8454532088],
        [72.1193714507, -106.8486973397],
        [72.1524144058, -106.8519536723],
        [72.1854601821, -106.8552222755],
        [72.2185087748, -106.8585032147],
        [72.2515601778, -106.861796568],
        [72.2846134869, -106.8651939816],
        [72.3176681043, -106.8687558003],
        [72.3507255072, -106.8723311672],
        [72.3837856903, -106.8759201599],
        [72.4168486482, -106.8795228562],
        [72.463369684, -104.9707464144],
        [72.5013331514, -102.2894608691],
        [72.5095841229, -99.9669571918],
        [72.0024118627, -99.0457543249],
        [72.0123646144, -97.1346669091],
        [72.0127039129, -97.0031371154],
        [72.0129499064, -96.8716029735],
        [72.0131025911, -96.7400658654],
        [72.013161964, -96.608527186],
        [72.0131280244, -96.4769883176],
        [72.0130007722, -96.3454506445],
        [72.0127802114, -96.213915556],
        [72.0124663437, -96.0823844397],
        [72.0120591743, -95.9508586777],
        [72.0115587112, -95.8193396603],
        [72.0109649622, -95.6878287663],
        [72.0102779369, -95.5563273889],
        [72.0094976462, -95.4248369058],
        [72.0085549424, -95.2933656495],
        [72.0070962783, -95.1619546798],
        [72.0055444751, -95.0305660445],
        [72.0038995577, -94.8992011211],
        [72.0021615532, -94.7678612841],
        [72.0003304903, -94.636547906],
        [71.9984063986, -94.5052623599],
        [71.2862677162, -94.5123648145],
        [70.7268380063, -94.4997601477],
        [70.254197071, -94.507822138],
        [69.899078641, -93.8280852771],
        [69.4520550539, -93.0075315125],
        [68.9227872869, -93.003810041],
        [68.3874656421, -93.0171924751],
        [67.728592525, -92.9951903408],
        [67.005280869, -92.9825304649],
        [66.223306404, -94.7177058929],
        [65.6092795688, -96.0067934958],
        [64.9965438696, -97.2646416546],
        [65.2087682218, -98.787721916],
        [65.423380049, -100.6003068345],
        [65.5550452839, -101.7520063944],
        [65.741523393, -103.7681152715],
        [65.9119943079, -105.8730638521],
        [66.0483354786, -107.9703983973],
        [66.1420473325, -109.9763414217],
        [66.7406486074, -109.9804124759],
        [67.3272903699, -110.0204630569],
        [68.0537387054, -109.9796282809],
        [69.0819950604, -110.0047220731],
        [70.2188559576, -109.9792389259],
        [71.2534685774, -110.0019418769],
    ],
];

const northernBeaufortSeaCoordinates = [
    [
        [71.2459089894, -112.50004596],
        [71.2468758847, -112.375198282],
        [71.2477552534, -112.2503380949],
        [71.2485470811, -112.1254665876],
        [71.2492513571, -112.0005849464],
        [71.24986807, -111.8756943516],
        [71.2503972115, -111.7507959943],
        [71.2508387737, -111.6258910546],
        [71.2511927489, -111.5009807267],
        [71.2514591321, -111.3760661914],
        [71.2516379199, -111.2511486411],
        [71.251729109, -111.1262292582],
        [71.2517326987, -111.0013092353],
        [71.2519761356, -110.8763967684],
        [71.2524521253, -110.7514862808],
        [71.2528405282, -110.6265699364],
        [71.2531413389, -110.5016489186],
        [71.2533545509, -110.3767244147],
        [71.2534801618, -110.2517976175],
        [71.2535181718, -110.1268697086],
        [71.2534685774, -110.0019418769],
        [70.2188559576, -109.9792389259],
        [69.0819950604, -110.0047220731],
        [68.0537387054, -109.9796282809],
        [67.3272903699, -110.0204630569],
        [67.3326470537, -111.0984458284],
        [67.3277695103, -112.7265833001],
        [67.3305525086, -114.2891809429],
        [67.3379936084, -115.9721967425],
        [67.334677815, -118.0513908204],
        [67.3344950896, -120.2545126027],
        [67.3255904095, -121.6501458783],
        [67.490715276, -123.1680434121],
        [67.545413632, -123.6670165541],
        [67.9634513046, -128.7214190815],
        [68.0257812725, -129.7790960873],
        [68.1760302882, -132.9755896371],
        [68.177560764, -132.9868035013],
        [71.4232322372, -132.9800589155],
        [71.4244747073, -132.9800558868],
        [71.4233338618, -132.9811255047],
        [71.5974262996, -134.9376372841],
        [71.8619317474, -137.8853326898],
        [72.16977757, -141.0063330695],
        [72.7624492611, -141.0087391535],
        [73.365417072, -141.0147757167],
        [74.2788484737, -141.0187738691],
        [75.6956205053, -141.0247838542],
        [77.4429838855, -141.0143764724],
        [78.4022014294, -141.0396884541],
        [79.4686604731, -141.0784327721],
        [80.5686763828, -141.2350322059],
        [81.2505869511, -141.1223480224],
        [81.0775800135, -138.4897934374],
        [80.8766272652, -133.9059402204],
        [80.6001357361, -128.3785795604],
        [80.2147496821, -122.7281764672],
        [79.9490417385, -119.8136628539],
        [79.7047192367, -116.6355112538],
        [79.43964369, -113.3140867146],
        [79.0800047722, -110.009593157],
        [78.1222748427, -110.0270564424],
        [77.1578982644, -109.992722724],
        [77.1286298511, -112.050499791],
        [77.1004808555, -115.7229516689],
        [77.0717811553, -118.6697509014],
        [76.1356566805, -118.5263757691],
        [75.1736919093, -118.5021225242],
        [74.3249762761, -118.5537692209],
        [74.3339045803, -116.9771767406],
        [73.6899708853, -116.9039174393],
        [73.1734099329, -116.7656756478],
        [73.0541815139, -116.4876932423],
        [72.6746328205, -116.5006790853],
        [72.2837110106, -116.5076280422],
        [72.0054450489, -116.5193714684],
        [71.771466983, -115.2839896116],
        [71.5078715006, -113.8569655514],
    ],
];

const norwegianBayCoordinates = [
    [
        [76.8310311908, -83.0016726201],
        [76.8282353502, -85.5593252553],
        [76.7409598141, -86.9419523543],
        [76.5080672208, -89.649972838],
        [76.4043830877, -92.629951371],
        [76.6916531735, -94.9929857924],
        [76.8821983156, -96.8563143106],
        [77.0049277757, -99.5046377889],
        [77.1202911376, -103.2508949409],
        [77.1708811805, -106.9091016406],
        [77.5418924176, -105.3701498049],
        [77.9918467381, -103.4170261808],
        [78.4519352807, -101.3839057358],
        [79.076617549, -98.6631028971],
        [79.5855593821, -96.4728485319],
        [79.5831013166, -93.0161437317],
        [79.5521128645, -89.6229659089],
        [79.52878895, -86.1464193987],
        [79.4874173389, -83.1821312236],
        [79.4711215041, -79.5875542846],
        [79.098828162, -80.3299097543],
        [78.8127756034, -80.8720828992],
        [78.4845732576, -81.5466613778],
        [78.100806374, -81.8974052598],
        [77.483260011, -82.4437497846],
        [77.45064822, -82.472785657],
        [77.4180352076, -82.5016698462],
        [77.3854210048, -82.5304035256],
        [77.3528056418, -82.5589878541],
        [77.3201891479, -82.5874239825],
        [77.2875715544, -82.6157130424],
        [77.2549528887, -82.6438561562],
        [77.2223331806, -82.6718544395],
        [77.1897124589, -82.6997089943],
        [77.1570907521, -82.7274209113],
        [77.124468089, -82.7549912762],
        [77.0918444971, -82.7824211467],
        [77.0592200046, -82.8097115873],
        [77.0265946391, -82.8368636461],
        [76.9939684274, -82.8638783639],
        [76.9613483097, -82.8909162235],
        [76.9287705302, -82.9188141036],
        [76.8961917315, -82.9465721097],
        [76.8636119422, -82.9741912693],
    ],
];

const southeastGreenlandCoordinates = [
    [
        [64.0000093388, -42.4421274132],
        [64.0000000004, -42.3999999993],
        [63.9999999997, -42.2999999994],
        [64.0000000003, -42.2000000003],
        [64.0000000004, -42.1],
        [63.9999999999, -42.0000000003],
        [64.0000000002, -41.899999999],
        [63.9999999998, -41.8000000002],
        [63.9999999998, -41.6999999997],
        [64.0, -41.5999999994],
        [64.0000000001, -41.4999999994],
        [63.9999999996, -41.3999999998],
        [63.9999999996, -41.3000000005],
        [63.9999999998, -41.1999999996],
        [64.0000000003, -41.0999999993],
        [64.0000000004, -40.9999999998],
        [64.0000000004, -40.8999999992],
        [64.0000000002, -40.7999999999],
        [63.9999999997, -40.7000000001],
        [64.0000095102, -40.6458424365],
        [64.0000000002, -40.5999999999],
        [64.0000000003, -40.4999999999],
        [63.9999999999, -40.4000000006],
        [63.9999999999, -40.3000000001],
        [63.9999999997, -40.1999999991],
        [64.0, -40.1],
        [63.9999999996, -39.9999999996],
        [63.9999999996, -39.9000000002],
        [64.0000000004, -39.8000000005],
        [63.9999999998, -39.6999999995],
        [63.9999999996, -39.5999999998],
        [64.0, -39.5],
        [63.9999999995, -39.3999999993],
        [63.9999999999, -39.3000000003],
        [64.0, -39.2],
        [64.0000000002, -39.0999999996],
        [64.0000000001, -39.0000000001],
        [64.0000000003, -38.9000000005],
        [64.0, -38.8000000001],
        [64.0000000003, -38.7],
        [64.0000059663, -38.680699401],
        [60.388918169, -41.3958571074],
        [60.281079923, -41.4663988326],
        [60.1588379677, -41.5457259921],
        [60.1419015372, -41.556663761],
        [60.1888263809, -43.9146898591],
        [60.3672961359, -43.9078548518],
        [60.370254522, -44.2872229675],
        [62.4294099531, -44.2326982311],
        [62.4389999401, -44.2324256182],
        [62.463129184, -44.1439185792],
        [63.7150267331, -42.7780089215],
    ],
];

const southernBeaufortSeaCoordinates = [
    [
        [72.1884084672, -165.8380066246],
        [72.3438914505, -163.0318149861],
        [72.5125333076, -159.31653843],
        [72.6569666265, -154.7671571093],
        [72.7437323055, -150.392581769],
        [72.7896695676, -145.8645313094],
        [72.7624492611, -141.0087391535],
        [72.16977757, -141.0063330695],
        [71.8619317474, -137.8853326898],
        [71.5974262996, -134.9376372841],
        [71.4233338618, -132.9811255047],
        [71.4232322372, -132.9800589155],
        [68.177560764, -132.9868035013],
        [68.1760302882, -132.9755896371],
        [68.3432554045, -136.9075544132],
        [68.4314743121, -139.7583059511],
        [68.4808316692, -142.5169797553],
        [68.4833833706, -145.1226601599],
        [68.4362172108, -147.6966604964],
        [68.3757864814, -150.1358324925],
        [68.2795112284, -153.1223470765],
        [68.1568076772, -157.1339019281],
        [68.019288588, -159.3393721981],
        [67.9022671953, -161.5331236677],
        [68.6639342605, -162.2244818486],
        [69.6203314058, -163.0780502344],
        [71.0522384994, -164.5407182294],
    ],
];

const southernHudsonBayCoordinates = [
    [
        [58.2620670892, -88.5114048041],
        [58.3492936292, -88.5110748759],
        [58.4365565565, -88.5107430285],
        [58.5238557946, -88.5104092498],
        [58.6111912665, -88.5100735184],
        [58.6985628952, -88.5097358216],
        [58.7859706034, -88.5093961398],
        [58.8734143128, -88.5090544543],
        [58.9608939476, -88.5087107485],
        [59.0484094274, -88.5083650044],
        [59.1359606764, -88.5080172037],
        [59.223547156, -88.5074390486],
        [59.3111684985, -88.5064861344],
        [59.3988253652, -88.5055275036],
        [59.4865176762, -88.5045631012],
        [59.5742453541, -88.503592876],
        [59.6620083168, -88.5026167752],
        [59.7498064865, -88.5016347465],
        [59.8376397824, -88.5006467319],
        [59.9255081245, -88.4996526816],
        [60.0134114316, -88.4986525363],
        [59.9968837911, -87.5434723739],
        [60.0026230393, -86.3275376975],
        [60.002981786, -86.2182518081],
        [60.0032361381, -86.1089635824],
        [60.0033860937, -85.9996738179],
        [60.0034316503, -85.8903833088],
        [60.0033728074, -85.7810928502],
        [60.003209565, -85.6718032364],
        [60.0029419264, -85.5625152649],
        [60.0025698932, -85.4532297299],
        [60.0020934721, -85.3439474237],
        [60.0015126681, -85.2346691463],
        [60.000943902, -85.1253945437],
        [60.0010145325, -85.016114508],
        [60.0009807756, -84.9068343318],
        [60.0008426317, -84.7975548102],
        [60.0006001026, -84.6882767384],
        [60.0002531905, -84.5790009101],
        [59.9998019019, -84.4697281236],
        [59.9992462401, -84.3604591703],
        [59.9985862128, -84.2511948459],
        [59.997821828, -84.1419359447],
        [60.0021464434, -82.4185569883],
        [59.9884547965, -80.4418699935],
        [59.9962397357, -78.6807219436],
        [59.9871555481, -76.7978310879],
        [59.9843329475, -75.845708999],
        [60.0091728994, -74.5379198345],
        [59.9994222647, -73.0006982395],
        [59.1108574844, -72.9997400641],
        [58.0448901966, -73.0025650043],
        [56.9867317756, -72.9971061734],
        [56.1044726419, -72.9976054604],
        [55.2406382433, -72.9967688335],
        [54.6186418168, -73.4368244407],
        [53.9180238271, -73.930037032],
        [52.9534681691, -74.6085194443],
        [51.8486158176, -75.390509078],
        [50.607588367, -76.2613824584],
        [49.7110734315, -76.8868309757],
        [49.6628585515, -78.5185589243],
        [49.6237620683, -79.7441402212],
        [49.5862012104, -80.9462046353],
        [49.5538845035, -81.9866843483],
        [50.1609187354, -82.8525637069],
        [50.7493654623, -83.6843664773],
        [51.5271988267, -84.787241244],
        [52.1974314259, -85.7348985145],
        [52.8776292714, -86.6959877512],
        [53.351576685, -87.3690295267],
        [53.6681444112, -87.9466057645],
        [54.0273735264, -88.6173427491],
        [55.1764607856, -88.5932208673],
        [56.1643217469, -88.5075170193],
        [57.0780609491, -88.4983797917],
    ],
];

const viscountMelvilleSoundCoordinates = [
    [
        [71.2459089894, -112.50004596],
        [71.5078715006, -113.8569655514],
        [71.771466983, -115.2839896116],
        [72.0054450489, -116.5193714684],
        [72.2837110106, -116.5076280422],
        [72.6746328205, -116.5006790853],
        [73.0541815139, -116.4876932423],
        [73.1734099329, -116.7656756478],
        [73.6899708853, -116.9039174393],
        [74.3339045803, -116.9771767406],
        [74.3249762761, -118.5537692209],
        [75.1736919093, -118.5021225242],
        [76.1356566805, -118.5263757691],
        [77.0717811553, -118.6697509014],
        [77.1004808555, -115.7229516689],
        [77.1286298511, -112.050499791],
        [77.1578982644, -109.992722724],
        [77.1708811805, -106.9091016406],
        [75.8123986665, -106.9080922515],
        [74.3781632735, -106.9098937877],
        [73.3127180233, -106.8645872408],
        [72.4168486482, -106.8795228562],
        [72.3837856903, -106.8759201599],
        [72.3507255072, -106.8723311672],
        [72.3176681043, -106.8687558003],
        [72.2846134869, -106.8651939816],
        [72.2515601778, -106.861796568],
        [72.2185087748, -106.8585032147],
        [72.1854601821, -106.8552222755],
        [72.1524144058, -106.8519536723],
        [72.1193714507, -106.8486973397],
        [72.0863313222, -106.8454532088],
        [72.0532940255, -106.8422212111],
        [72.0202595659, -106.8390012787],
        [71.9872279486, -106.8357933441],
        [71.9541991781, -106.8325973419],
        [71.9211732617, -106.8294132055],
        [71.8881502029, -106.8262408651],
        [71.8551300075, -106.8230802579],
        [71.8221126805, -106.8199313189],
        [71.7890982266, -106.816793985],
        [71.7560866525, -106.8136681895],
        [71.3496154074, -106.7719498855],
        [71.3479205203, -106.8691799618],
        [71.3461728443, -106.9663917874],
        [71.3443723956, -107.0635848039],
        [71.3425191922, -107.1607584614],
        [71.3406132489, -107.257912207],
        [71.3386545833, -107.3550454843],
        [71.3366432148, -107.4521577454],
        [71.3345791596, -107.5492484395],
        [71.3324624372, -107.6463170151],
        [71.3302930671, -107.7433629189],
        [71.3278923155, -107.8403436061],
        [71.3254364578, -107.9372980408],
        [71.3229280642, -108.034226263],
        [71.3203671549, -108.1311277263],
        [71.317753755, -108.2280018876],
        [71.3150878864, -108.3248482074],
        [71.3123695748, -108.421666142],
        [71.3095988431, -108.5184551536],
        [71.3067757177, -108.6152147007],
        [71.3039002225, -108.7119442475],
        [71.2534685774, -110.0019418769],
        [71.2535181718, -110.1268697086],
        [71.2534801618, -110.2517976175],
        [71.2533545509, -110.3767244147],
        [71.2531413389, -110.5016489186],
        [71.2528405282, -110.6265699364],
        [71.2524521253, -110.7514862808],
        [71.2519761356, -110.8763967684],
        [71.2517326987, -111.0013092353],
        [71.251729109, -111.1262292582],
        [71.2516379199, -111.2511486411],
        [71.2514591321, -111.3760661914],
        [71.2511927489, -111.5009807267],
        [71.2508387737, -111.6258910546],
        [71.2503972115, -111.7507959943],
        [71.24986807, -111.8756943516],
        [71.2492513571, -112.0005849464],
        [71.2485470811, -112.1254665876],
        [71.2477552534, -112.2503380949],
        [71.2468758847, -112.375198282],
    ],
];

const westernHudsonBayCoordinates = [
    [
        [63.1677084323, -90.7939516115],
        [63.1684969497, -90.6791236995],
        [63.1691814467, -90.5642892573],
        [63.1697619144, -90.4494492042],
        [63.1702383468, -90.3346044641],
        [63.1706107351, -90.2197559594],
        [63.1708790762, -90.1049046143],
        [63.171043366, -89.9900513476],
        [63.1711036022, -89.8751970878],
        [63.171059784, -89.7603427524],
        [63.1709119121, -89.64548927],
        [63.1706599884, -89.5306375608],
        [63.1703040156, -89.4157885464],
        [63.1698440003, -89.300943151],
        [63.1692799467, -89.1861022984],
        [63.1686118625, -89.0712669085],
        [63.1678397577, -88.9564379033],
        [63.1671653867, -88.8416135589],
        [63.1669265726, -88.7267861775],
        [63.1665837399, -88.6119613737],
        [63.1661368933, -88.4971400724],
        [62.695821262, -88.4923447362],
        [61.9559161849, -88.4926204285],
        [61.1604663275, -88.4860702225],
        [60.5214012321, -88.4910814353],
        [60.0134114316, -88.4986525363],
        [59.9255081245, -88.4996526816],
        [59.8376397824, -88.5006467319],
        [59.7498064865, -88.5016347465],
        [59.6620083168, -88.5026167752],
        [59.5742453541, -88.503592876],
        [59.4865176762, -88.5045631012],
        [59.3988253652, -88.5055275036],
        [59.3111684985, -88.5064861344],
        [59.223547156, -88.5074390486],
        [59.1359606764, -88.5080172037],
        [59.0484094274, -88.5083650044],
        [58.9608939476, -88.5087107485],
        [58.8734143128, -88.5090544543],
        [58.7859706034, -88.5093961398],
        [58.6985628952, -88.5097358216],
        [58.6111912665, -88.5100735184],
        [58.5238557946, -88.5104092498],
        [58.4365565565, -88.5107430285],
        [58.3492936292, -88.5110748759],
        [58.2620670892, -88.5114048041],
        [57.0780609491, -88.4983797917],
        [56.1643217469, -88.5075170193],
        [55.1764607856, -88.5932208673],
        [55.2470465739, -90.1208440645],
        [55.308452419, -91.6464895645],
        [55.3920532574, -93.6341768864],
        [55.4863482615, -95.4892563458],
        [55.5530842848, -97.4546543454],
        [56.3774301789, -97.4442950413],
        [57.4244681197, -97.4333970203],
        [58.8770346904, -97.3926633336],
        [59.803947639, -97.3447702908],
        [60.8068241961, -97.3026631155],
        [61.9847125395, -97.2518932419],
        [63.1667400612, -97.2523661571],
        [63.1677310419, -95.9237703057],
        [63.1667759217, -94.253468777],
        [63.1687063088, -92.5549629658],
    ],
];

const arcticBasinPolygonOptions = {
    color: "#666666",
    fillColor: "#ff0029",
    fillOpacity: 0.5,
};

const baffinBayPolygonOptions = {
    color: "#666666",
    fillColor: "#00ff00",
    fillOpacity: 0.5,
};

const barentsSeaPolygonOptions = {
    color: "#666666",
    fillColor: "#8dff00",
    fillOpacity: 0.5,
};

const chukchiSeaPolygonOptions = {
    color: "#666666",
    fillColor: "#005eff",
    fillOpacity: 0.5,
};

const davisStraitPolygonOptions = {
    color: "#666666",
    fillColor: "#ffa400",
    fillOpacity: 0.5,
};

const eastGreenlandPolygonOptions = {
    color: "#666666",
    fillColor: "#2a00ff",
    fillOpacity: 0.5,
};

const foxeBasinPolygonOptions = {
    color: "#666666",
    fillColor: "#0018ff",
    fillOpacity: 0.5,
};

const gulfOfBoothiaPolygonOptions = {
    color: "#666666",
    fillColor: "#ff1800",
    fillOpacity: 0.5,
};

const kaneBasinPolygonOptions = {
    color: "#666666",
    fillColor: "#b700ff",
    fillOpacity: 0.5,
};

const karaSeaPolygonOptions = {
    color: "#666666",
    fillColor: "#00ecff",
    fillOpacity: 0.5,
};

const lancasterSoundPolygonOptions = {
    color: "#666666",
    fillColor: "#00a5ff",
    fillOpacity: 0.5,
};

const laptevSeaPolygonOptions = {
    color: "#666666",
    fillColor: "#ffea00",
    fillOpacity: 0.5,
};

const mclintockChannelPolygonOptions = {
    color: "#666666",
    fillColor: "#46ff00",
    fillOpacity: 0.5,
};

const northernBeaufortSeaPolygonOptions = {
    color: "#666666",
    fillColor: "#7000ff",
    fillOpacity: 0.5,
};

const norwegianBayPolygonOptions = {
    color: "#666666",
    fillColor: "#00ffcc",
    fillOpacity: 0.5,
};

const southeastGreenlandPolygonOptions = {
    color: "#666666",
    fillColor: "#00ff8c",
    fillOpacity: 0.5,
};

const southernBeaufortSeaPolygonOptions = {
    color: "#666666",
    fillColor: "#00ff8c",
    fillOpacity: 0.5,
};

const southernHudsonBayPolygonOptions = {
    color: "#666666",
    fillColor: "#cdff00",
    fillOpacity: 0.5,
};

const viscountMelvilleSoundPolygonOptions = {
    color: "#666666",
    fillColor: "#fe00ff",
    fillOpacity: 0.5,
};

const westernHudsonBayPolygonOptions = {
    color: "#666666",
    fillColor: "#ff5e00",
    fillOpacity: 0.5,
};

const ARCTIC_TILES_URL =
    "https://tile.gbif.org/3575/omt/{z}/{x}/{y}@4x.png?style=osm-bright-en";

const LabelMarker = ({ position, content }) => {
    const map = useMap();

    React.useEffect(() => {
        const marker = L.marker(position, { opacity: 0.001 });
        marker.bindTooltip(content, {
            permanent: true,
            className: "pbsg-region",
            offset: [0, 20],
            direction: "center", // centers the tooltip on the marker
        });
        marker.addTo(map);

        // Cleanup on component unmount
        return () => {
            marker.remove();
        };
    }, [map, position, content]);

    return null;
};

const App = () => {
    return (
        <div className="App">
            <MapContainer
                className="Map"
                crs={ARCTIC_LAEA}
                center={[90, 135]}
                continuousWorld={true}
                zoom={2}
                minZoom={2}
                maxZoom={MAX_ZOOM}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution=""
                    url={ARCTIC_TILES_URL}
                    tileSize={TILE_SIZE}
                    minZoom={2}
                    maxZoom={MAX_ZOOM}
                    scrollWheelZoom={false}
                />
                <Polygon
                    positions={arcticBasinCoordinates}
                    {...arcticBasinPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Arctic Basin</b>
                        <br />
                        Population: Unknown
                        <br />
                        Year of Estimate: N/A
                        <br />
                        Citation: N/A
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#arctic_basin">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[87, 180]} content="AB" />
                <Polygon positions={baffinBayCoordinates} {...baffinBayPolygonOptions}>
                    <Popup className="non-interactive">
                        <b>Baffin Bay</b>
                        <br />
                        Population: 2826 (95% CI = 2059–3593)
                        <br />
                        Year of Estimate: 2013
                        <br />
                        Citation: SWG 2016
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#baffin_bay">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[71, -65]} content="BB" />
                <Polygon
                    positions={barentsSeaCoordinates}
                    {...barentsSeaPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Barents Sea</b>
                        <br />
                        Population: 2644 (95% CI = 1899–3592)
                        <br />
                        Year of Estimate: 2004
                        <br />
                        Citation: Aars et al. 2009
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#barents_sea">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[78, 33]} content="BS" />
                <Polygon
                    positions={chukchiSeaCoordinates}
                    {...chukchiSeaPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Chukchi Sea</b>
                        <br />
                        Population: 2937 (95% CI = 1552–5944)
                        <br />
                        Year of Estimate: 2016
                        <br />
                        Citation: Regehr et al. 2018a
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#chukchi_sea">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[67, -173.5]} content="CS" />

                <Polygon
                    positions={davisStraitCoordinates}
                    {...davisStraitPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Davis Strait</b>
                        <br />
                        Population: 2015 (95% CI = 1603-2588)
                        <br />
                        Year of Estimate: 2018
                        <br />
                        Citation: Dyck et al. 2022
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#davis_strait">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[58, -60]} content="DS" />
                <Polygon
                    positions={eastGreenlandCoordinates}
                    {...eastGreenlandPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>East Greenland</b>
                        <br />
                        Population: Unknown
                        <br />
                        Year of Estimate: N/A
                        <br />
                        Citation: N/A
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#east_greenland">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[75, -18]} content="EG" />
                <Polygon positions={foxeBasinCoordinates} {...foxeBasinPolygonOptions}>
                    <Popup className="non-interactive">
                        <b>Foxe Basin</b>
                        <br />
                        Population: 2585 (95% CI = 2096–3189)
                        <br />
                        Year of Estimate: 2010
                        <br />
                        Citation: Stapleton et al. 2016
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#foxe_basin">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[64, -80]} content="FB" />
                <Polygon
                    positions={gulfOfBoothiaCoordinates}
                    {...gulfOfBoothiaPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Gulf of Boothia</b>
                        <br />
                        Population: 1525 (95% CI = 949–2101)
                        <br />
                        Year of Estimate: 2017
                        <br />
                        Citation: Dyck et al. 2020b
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#gulf_of_boothia">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[68, -87]} content="GB" />
                <Polygon positions={kaneBasinCoordinates} {...kaneBasinPolygonOptions}>
                    <Popup className="non-interactive">
                        <b>Kane Basin</b>
                        <br />
                        Population: 357 (95% CI = 221–493)
                        <br />
                        Year of Estimate: 2014
                        <br />
                        Citation: SWG 2016
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#kane_basin">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[77.3, -69]} content="KB" />
                <Polygon positions={karaSeaCoordinates} {...karaSeaPolygonOptions}>
                    <Popup className="non-interactive">
                        <b>Kara Sea</b>
                        <br />
                        Population: Unknown
                        <br />
                        Year of Estimate: N/A
                        <br />
                        Citation: N/A
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#kara_sea">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[75, 73]} content="KS" />
                <Polygon
                    positions={lancasterSoundCoordinates}
                    {...lancasterSoundPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Lancaster Sound</b>
                        <br />
                        Population: 2541 (95% CI = 1759–3323)
                        <br />
                        Year of Estimate: 1997
                        <br />
                        Citation: Taylor et al. 2008b
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#lancaster_sound">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[73, -90]} content="LS" />
                <Polygon positions={laptevSeaCoordinates} {...laptevSeaPolygonOptions}>
                    <Popup className="non-interactive">
                        <b>Laptev Sea</b>
                        <br />
                        Population: Unknown
                        <br />
                        Year of Estimate: N/A
                        <br />
                        Citation: N/A
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#laptev_sea">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[75, 142]} content="LP" />
                <Polygon
                    positions={mcclintockChannelCoordinates}
                    {...mclintockChannelPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>M'Clintock Channel</b>
                        <br />
                        Population: 716 (95% CRI = 545–955)
                        <br />
                        Year of Estimate: 2016
                        <br />
                        Citation: Dyck et al. 2020a
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#mclintock_channel">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[68, -99]} content="MC" />
                <Polygon
                    positions={northernBeaufortSeaCoordinates}
                    {...northernBeaufortSeaPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Northern Beaufort Sea</b>
                        <br />
                        Population: 980 (95% CI = 825–1135)
                        <br />
                        Year of Estimate: 2006
                        <br />
                        Citation: Stirling et al. 2011
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#northern_beaufort_sea">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[74.5, -125]} content="NB" />
                <Polygon
                    positions={norwegianBayCoordinates}
                    {...norwegianBayPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Norwegian Bay</b>
                        <br />
                        Population: 203 (95% CI = 115–291)
                        <br />
                        Year of Estimate: 1997
                        <br />
                        Citation: Taylor et al. 2008b
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#norwegian_bay">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[77, -88]} content="NW" />
                <Polygon
                    positions={southeastGreenlandCoordinates}
                    {...southeastGreenlandPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Southeast Greenland</b>
                        <br />
                        Population: Unknown
                        <br />
                        Year of Estimate: N/A
                        <br />
                        Citation: N/A
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#southeast_greenland">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[60.8, -42.5]} content="SE" />
                <Polygon
                    positions={southernBeaufortSeaCoordinates}
                    {...southernBeaufortSeaPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Southern Beaufort Sea</b>
                        <br />
                        Population: ~900 (90% CI = 606–1212)
                        <br />
                        Year of Estimate: 2010
                        <br />
                        Citation: Bromaghin et al. 2015
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#southern_beaufort_sea">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[70.5, -142]} content="SB" />
                <Polygon
                    positions={southernHudsonBayCoordinates}
                    {...southernHudsonBayPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Southern Hudson Bay</b>
                        <br />
                        Population: 1119 (95% CI = 860-1454)
                        <br />
                        Year of Estimate: 2021
                        <br />
                        Citation: Northrup et al. 2022
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#southern_hudson_bay">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[55, -80]} content="SH" />
                <Polygon
                    positions={viscountMelvilleSoundCoordinates}
                    {...viscountMelvilleSoundPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Viscount Melville Sound</b>
                        <br />
                        Population: 161 (95% CI = 93–229)
                        <br />
                        Year of Estimate: 1992
                        <br />
                        Citation: Taylor et al. 2002
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#viscount_melville_sound">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[73.5, -108]} content="VM" />
                <Polygon
                    positions={westernHudsonBayCoordinates}
                    {...westernHudsonBayPolygonOptions}
                >
                    <Popup className="non-interactive">
                        <b>Western Hudson Bay</b>
                        <br />
                        Population: 618 (95% CI = 425-899)
                        <br />
                        Year of Estimate: 2021
                        <br />
                        Citation: Atkinson et al. 2022
                        <br />
                        <a target="_top" href="https://www.iucn-pbsg.org/population-status/#western_hudson_bay">
                            Learn More
                        </a>
                    </Popup>
                </Polygon>
                <LabelMarker position={[58, -91]} content="WH" />
            </MapContainer>
        </div>
    );
};

export default App;
